@use "../abstracts" as *;

/* btn
********************************************** */
.btn:not(:last-child) {
  margin-bottom: rem(8);
}
.btn a {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: rem(14) rem(32);
  gap: rem(16);
  width: fit-content;
  min-width: min(rem(280), 100%);
  color: var(--clr-wht);
  font-weight: 500;
  background-color: var(--bg-main);
  border-radius: rem(6);
  @include mq(sp) {
    min-width: 100%;
  }
  &:hover {
    background-color: var(--clr-blu3);
  }
  &:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"])::before {
    content: "";
    display: block;
    @include rect(8);
    margin-right: 0;
    margin-left: auto;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    transition: var(--transit-default);
    order: 1;
  }
  svg {
    margin-right: 0;
    margin-left: auto;
    fill: currentColor;
  }
}
.btn.btn-ctr a {
  @include auto-margin;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--clr-main);
  background-color: var(--bg-wht);
  &:hover {
    color: var(--clr-wht);
    background-color: var(--bg-main);
  }
}

/* --- btn-cta --- */
.btn-cta a,
.btn-org a {
  background-color: var(--bg-cta);
  &:hover {
    background-color: var(--bg-cta-hvr);
  }
}

/* --- btn-arrow --- */
.btn-arrow a {
  display: inline-flex;
  align-items: center;
  gap: rem(10);
  margin-inline: -1em;
  padding: 1em;
  &:hover {
    color: var(--clr-main);
  }
  &::after {
    content: "";
    @include rect(8);
    color: var(--clr-main);
    transform: rotate(45deg);
    border-top: 2px solid;
    border-right: 2px solid;
  }
}

/* --- 横並び --- */
.flex > .btn {
  margin-bottom: 0;
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  &:only-child {
    @include mq(sp, min, ps) {
      width: fit-content;
      min-width: min(rem(280),100%);
    }
  }
  a {
    min-width: 100%;
    height: 100%;
  }
}

/* --- cta_btn --- */
.cta_btn a,
.c-cv-button {
  position: relative;
  @include center-flex;
  gap: rem(24);
  padding: rem(16) rem(32);
  width: fit-content;
  min-height: 80px;
  color: var(--clr-wht);
  font-weight: 600;
  background-color: var(--clr-org);
  border-radius: rem(4);
  overflow: hidden;
  @include mq(sp) {
    gap: sprem(8);
    @include p-lr(sprem(16));
    margin-inline: auto;
    min-height: 64px;
  }
  span {
    position: relative;
    z-index: 2;
  }
  .icon,
  span:first-child {
    @include center-flex;
    @include rect(56);
    font-size: rem(12);
    line-height: 1.2;
    color: var(--clr-org);
    background-color: var(--clr-wht);
    border-radius: rem(2);
    aspect-ratio: 1;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: rem(60);
    translate: -50% -50%;
    width: 200%;
    aspect-ratio: 1;
    background-color: rgba($clr-wht, 0.3);
    border-radius: 100vw;
    z-index: 1;
    transition: var(--transit-default);
    scale: 0;
  }
  &:hover {
    color: var(--clr-wht);
  }
  &:hover::after  {
    scale: 1;
  }
}