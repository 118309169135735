@use '../abstracts' as *;

/* ##############################################################################

  FORM

############################################################################## */

.page-entry__content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: rem(32);
  border-bottom: 1px dotted var(--clr-gry2);
  @include mq(sp) {
    padding: rem(24) rem(16);
  }
  input {
    @include mq(sp) {
      font-size: 16px;
    }
  }
  input,
  select {
    padding: rem(16);
    @include mq(sp) {
      padding: rem(8);
      max-width: 100%;
    }
  }
  select {
    height: rem(48);
    padding: 0 rem(16);
    border-radius: 0;
    @include mq(sp) {
      height: rem(40);
    }
  }
  ::placeholder {
    color: var(--clr-gry2);
  }
}
.c-content-title-border-left + .page-entry__content {
  padding-top: rem(16);
  @include mq(sp) {
    padding-top: 0;
  }
}

.page-entry__content-2col {
  @include mq(sp, min, ps) {
    display: flex;
    border-bottom: 1px dotted var(--clr-gry2);
    .page-entry__content {
      width: 50%;
      border-bottom: 0;
    }
  }
}
.page-entry__content-note {
  width: 100%;
  font-size: rem(14);
  margin-top: rem(8);
}
.page-entry__content-title {
  position: relative;
  font-size: rem(18);
  margin-bottom: rem(16);
  padding-left: rem(24);
  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(0deg, var(--clr-blu) 0%, var(--clr-blu) 33.33%, var(--clr-blu2) 33.34%, var(--clr-blu2) 66.66%, var(--clr-lbl) 66.67%, var(--clr-lbl) 100%);
  }
}
.page-entry__radio-content,
.page-entry__check-content fieldset {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: rem(16);
  input {
    margin: 0 0.25em 0 0;
  }
  label {
    line-height: 1;
    cursor: pointer;
  }
}
.page-entry__birth-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: rem(8);
}
.page-entry__birth-content {
  flex: 1;
  input {
    width: 100%;
  }
}

.page-entry__content-privacy-link {
  display: flex;
  justify-content: center;
  gap: rem(32);
  font-weight: 600;
  @include p-tb(rem(24));
  a {
    color: var(--clr-blu);
    text-decoration: underline;
    transition: var(--transit-default);
    &:hover {
      color: var(--clr-blk2);
    }
  }
}

.page-entry__content-privacy-wrapper {
  text-align: center;
  margin-bottom: rem(16);
}

.c-form-submit-button {
  display: block;
  width: rem(320);
  height: rem(64);
  margin-inline: auto;
  font-size: rem(16);
  font-weight: 700;
  color: var(--clr-wht);
  background-color: var(--clr-org);
  border-radius: rem(6);
  border: none;
  cursor: pointer;
  transition: var(--transit-default);
  &:hover {
    opacity: .7;
  }
}

.error-message {
  margin-top: rem(8);
  color: var(--clr-red2);
  font-weight: 500;
}
#desiring-areas-error {
  width: 100%;
  .error-message {
    margin-top: 0;
  }
}