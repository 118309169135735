@use '../abstracts' as *;

/* ##############################################################################

    SINGLE

############################################################################## */

  /* --- アイキャッチ --- */
  .eyecatch {
    display: flex;
    justify-content: center;
    margin-bottom: rem(32);
  }

  /* --- 目次 --- */
  .ez-toc-debug-messages {
    display: none;
  }
  div#ez-toc-container {
    padding: rem(24);
    margin-bottom: rem(40);
    background-color: #fafafa;
    border-radius: rem(12);
    border-width: 2px;
  }
  .ez-toc-title-container {
    margin-bottom: rem(10);
  }
  div#ez-toc-container p.ez-toc-title {
    color: var(--clr-body);
    font-size: rem(16);
    font-weight: 500;
  }
  #ez-toc-container nav {
    padding-left: rem(56);
    @include mq(sp) {
      padding-left: rem(16);
    }
  }
  #ez-toc-container a {
    font-weight: 500;
  }
  #ez-toc-container.counter-hierarchy ul {
    font-size: calc-fz(17);
    @include mq(sp) {
      font-size: sprem(14);
    }
  }
  #ez-toc-container.counter-hierarchy ul li {
    margin-top: rem(8);
  }
  #ez-toc-container.counter-hierarchy ul ul {
    margin-left: rem(16);
  }
  #ez-toc-container.counter-hierarchy ul ul a {
    position: relative;
    display: inline-block;
    padding-left: rem(16);
    font-weight: 400;
    &::before {
      content: "";
      position: absolute;
      top: rem(14);
      left: 0;
      width: rem(8);
      height: 0;
      border-bottom: 1px solid;
    }
  }

/* pass
**************************************** */
  #pass p {
    margin-bottom: 1em;
  }
  #pass input[name="post_password"] {
    padding: rem(5);
    border: solid 1px #aaa;
    outline: none;
  }
  #pass input[type="submit"] {
    padding: rem(4) rem(6);
    letter-spacing: .15em;
    background-color: #ddd;
    border-radius: rem(4);
    outline: none;
    border: none;
    cursor: pointer;
    transition: var(--transit-default);
  }
  #pass input[type="submit"]:hover {
    opacity: .7;
  }

/* share
**************************************** */
  .share--list a {
    @include center-flex;
    height: rem(40);
    padding: .5em;
    line-height: 1;
    font-size: calc-fz(12);
    font-weight: bold;
    border: 1px solid #ddd;
  }
  .share--list li {
    &.x a { color: #000; }
    &.facebook a { color: #2477F2; }
    &.line a { color: #27C754; }
    &.pocket a { color: #EF4056; }
    &.linkedin a { color: #2867B2; }
    &.hatena a { color: #29A4DE; }
  }
  .share--list img {
    margin-right: rem(10);
  }
  .share--list a:hover {
    opacity: .5;
  }

/* wp-pagenavi
**************************************** */
  .wp-pagenavi {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: calc-clamp(4, $wid-var);
    white-space: nowrap;
  }
  .wp-pagenavi > * {
    @include center-flex;
  }
  .wp-pagenavi .pages {
    width: 100%;
    margin-bottom: 1em;
  }
  .wp-pagenavi a {
    position: relative;
    padding: 0 1em;
    height: calc-clamp(40, $wid-var);
    background-color: var(--bg-main);
    color: var(--clr-wht);
    font-weight: 600;
    @include mq(sp) {
      height: sprem(36);
    }
    &:hover {
      background-color: var(--clr-blu4);
    }
  }
  .archive .wp-pagenavi > *:not(.pages) {
    width: calc-clamp(40, $wid-var);
    aspect-ratio: 1/1;
    @include mq(sp) {
      width: sprem(36);
    }
  }
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    aspect-ratio: 1/1;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    border-top: 2px solid;
    border-right: 2px solid;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    width: .5em;
    aspect-ratio: 1/1;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .first::after {
    transform: rotate(-135deg);
  }
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .last::after {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="prev"]::before {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="next"]::before {
    transform: rotate(-135deg);
  }
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before {
    left: rem(-6);
  }
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    right: rem(-6);
  }


  /* column
  **************************************** */
  .ttl-column {
    color: var(--clr-blu);
    font-size: rem(26);
  }
  .posts-column .post.post-relation {
    padding: rem(24) rem(20);
    box-shadow: 0 0 13px 0 rgba($clr-blk, 0.13);
    border: none;
  }
  .posts-column .post-relation .post--img {
    width: 100%;
    margin-right: 0;
  }
  .posts-column .post-relation .post--ttl a {
    font-size: rem(18);
  }
  .posts-column .post-relation .cat_list.column a {
    padding: rem(12) rem(16);
  }
  .posts-column .post-relation .tag_list {
    gap: rem(12);
  }
  .posts-column .post-relation .tag_list a {
    font-size: rem(14);
  }

  /* case
  **************************************** */
  .case_info {
    @include mq(sp) {
      margin-bottom: rem(64);
    }
  }
  .case_info--img {
    width: rem(320);
    @include mq(sp) {
      width: 40% !important;
      margin-right: 0;
      margin-left: auto;
    }
  }
  .case_info--txt {
    flex: 1;
    @include mq(sp) {
      margin-top: rem(-8);
    }
  }
  .ttl-case {
    font-size: rem(28);
    @include mq(sp) {
      font-size: sprem(20);
      margin-bottom: rem(24);
    }
  }
  .case_interview--item:not(:last-child) {
    margin-bottom: rem(40);
  }
  .single-case {
    .case_data--box::after {
      @include mq(sp) {
        top: calc(50% - rem(8));
      }
    }
    .case_data.after .num {
      font-size: rem(28);
      letter-spacing: 0.05em;
    }
  }



  /* adviser
  **************************************** */
  .single-adviser {
    .adviser_point--list {
      gap: rem(8) rem(24);
      line-height: 1.4;
    }
    .adviser_point--list li {
      padding-top: 0.6em;
    }
  }
  .adviser_info--img {
    width: rem(260);
  }
  .adviser_info--txt {
    flex: 1;
  }
  .adviser_name-en {
    color: var(--clr-main);
  }
  .adviser_ttl {
    position: relative;
    padding-left: rem(20);
    margin-bottom: rem(20);
    color: var(--clr-blu);
    font-size: rem(18);
    letter-spacing: .2em;
    line-height: rem(24);
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: rem(26);
      background: linear-gradient(180deg, var(--clr-blu) 0%, var(--clr-blu) 33.33%, var(--clr-blu2) 33.34%, var(--clr-blu2) 66.66%, var(--clr-gry) 66.67%, var(--clr-gry) 100%);
    }
  }