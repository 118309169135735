@use "../abstracts" as *;

/* lps_parts--faq
********************************************** */
/* --- 共通 --- */
.lps_parts--faq {
  max-width: rem(960);
  @include auto-margin;
  .ttl-03 {
    font-size: calc-fz(20);
    margin-bottom: 0 !important;
    @include mq(sp) {
      font-size: sprem(17);
    }
  }
  .text {
    @include mq(sp, min, ps) {
      display: flex;
      gap: perc(32,768,'%');
    }
  }
  .img {
    @include mq(sp, min, ps) {
      width: perc(300,768,'%');
    }
  }
  .mce-content-body {
    @include mq(sp, min, ps) {
      margin-bottom: 0 !important;
      flex: 1;
    }
  }
}

/* --- 通常 --- */
.lps_parts--faq:not(.faq-toggle) {
  .faq_item {
    border: 2px solid var(--clr-main);
    background-color: var(--bg-wht);
    &:not(:last-child) {
      margin-bottom: rem(40);
      @include mq(sp) {
        margin-bottom: sprem(16);
      }
    }
  }
  .ttl-03,
  .text {
    padding: rem(28) rem(32);
    @include mq(sp) {
      padding: sprem(24);
    }
  }
  .ttl-03 {
    background-color: var(--clr-main);
    color: var(--clr-wht);
  }
}

/* --- 開閉式 --- */
.lps_parts--faq.faq-toggle {
  .faq_item {
    border-top: 1px solid var(--clr-gry2);
    border-bottom: 1px solid var(--clr-gry2);
    padding: rem(40) rem(32) rem(40) rem(64);
    @include mq(sp) {
      padding: sprem(24) 0;
    }
    &:not(:first-child) {
      border-top: 0;
    }
  }
  .ttl-03 {
    cursor: pointer;
    padding-right: rem(64);
    font-size: rem(22);
    @include mq(sp) {
      font-size: rem(20);
      padding-right: sprem(42);
      padding-left: sprem(42);
    }
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
    &::before {
      content: 'Q';
      position: absolute;
      top: 0;
      left: rem(-40);
      font-size: rem(30);
      line-height: 1;
      color: var(--clr-blu);
      font-family: var(--font-en);
      @include mq(sp) {
        left: sprem(16);
      }
    }
    &::after {
      content: '';
      position: absolute;
      inset: rem(-40) rem(-30);
      @include mq(sp) {
        inset: sprem(-24);
      }
    }
  }
  .ttl-03 .toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    @include rect(48);
    transition: var(--transit-default);
    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      width: rem(16);
      height: 2px;
      border-bottom: 2px solid var(--clr-blu);
      transition: var(--transit-default);
    }
    &::after {
      transform: rotate(90deg);
    }
  }
  .ttl-03:hover .toggle {
    opacity: .5;
  }
  .ttl-03.active .toggle {
    transform: rotate(180deg);
    &::after {
      opacity: 0;
    }
  }
  .text {
    position: relative;
    padding-top: rem(40);
    @include mq(sp) {
      padding-top: sprem(20);
      padding-left: sprem(42);
    }
    &::before {
      content: 'A';
      position: absolute;
      top: rem(40);
      left: rem(-40);
      font-size: rem(30);
      line-height: 1;
      color: var(--clr-gry4);
      font-family: var(--font-en);
      @include mq(sp) {
        top: sprem(24);
        left: sprem(16);
      }
    }
  }
}