@use "../abstracts" as *;

/* home_common
**************************************** */
.home_ttl-01 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: rem(8);
  font-size: rem(42);
  margin-bottom: rem(40);
  font-weight: 700;
  @include mq(sp) {
    gap: 0;
    font-size: sprem(28);
    margin-bottom: sprem(24);
  }
  .font-en {
    color: var(--clr-main);
    font-size: rem(26);
    font-weight: 400;
    @include mq(sp) {
      font-size: sprem(20);
    }
  }
}
.home_ttl-02 {
  margin-bottom: rem(56);
  font-size: rem(32);
  letter-spacing: rem(3.2);
  line-height: 1.4;
  text-align: center;
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
.home_ttl-03 {
  font-size: rem(24);
  line-height: 1.4;
  padding-left: rem(16);
  @include p-tb(rem(4));
  border-left: 5px solid var(--clr-blu);
}

/* --- btn --- */
.home .btn a,
.page-prefectures .btn a {
  @include mq(sp, min, ps) {
    min-width: 400px;
    min-height: 80px;
  }
  span {
    width: 100%;
    text-align: center;
  }
}

/* --- home_slide --- */
.home_slide {
  display: none;
  margin-bottom: rem(40);
  &.slick-initialized {
    display: block;
  }
  .slick-track {
    display: flex;
    padding-bottom: 1px;
  }
  .slick-slide {
    height: auto !important;
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    @include rect(40);
    text-decoration: none;
    background-color: var(--clr-blu);
    border-radius: 50vw;
    @include mq(sp) {
      @include rect(32)
    }
    &::after {
      content: '';
      display: block;
      @include rect(10,16);
      background-color: var(--clr-wht);
      clip-path: polygon(0 0, 2px 0, 100% 50%, 2px 100%, 0 100%, calc(100% - 2px) 50%);
    }
  }
  .slick-prev { 
    left: rem(-60);
    @include mq(sp) {
      left: sprem(-24);
    }
    &::after {
      rotate: 180deg;
    }
  }
  .slick-next { 
    right: rem(-60);
    @include mq(sp) {
      right: sprem(-24);
    }

  }
  .slick-dots {
    position: absolute;
    bottom: rem(-40);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include mq(med) {
      position: relative;
      bottom: rem(-16);
    }
  }
  .slick-dots li {
    display: block;
    @include rect(10);
    background-color: var(--clr-gry2);
    border-radius: 50%;
    cursor: pointer;
    transition: var(--transit-default);
    margin: rem(4);
    &.slick-active {
      background-color: var(--clr-blu);
    }
    &:hover {
      opacity: .7;
    }
  }
}


/* hero
********************************************** */
.hero {
  position: relative;
  @include p-tb(rem(148),rem(120));
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include mq(sp) {
    @include p-tb(sprem(120),sprem(64));
  }
  & > .inner {
    height: 100%;
  }
}
.hero--copy {
  margin-bottom: rem(56);
  color: var(--clr-wht);
  font-size: clamp(rem(34), vw(46), rem(46));
  letter-spacing: 0.03em;
  text-shadow: 5px 4px 13px rgba($clr-blk, 0.13);
  @include mq(sp) {
    font-size: rem(26);
    margin-bottom: rem(32);
    letter-spacing: 0.06em;
  }
  span {
    font-size: rem(100);
    font-size: clamp(rem(72), vw(96), rem(96));
    letter-spacing: 0;
    background-image: linear-gradient(transparent 75%, var(--clr-yel) 0%);
    margin-right: 0.1em;
    @include mq(sp) {
      font-size: rem(54);
    }
  }
}

@include mq(sp) {
  .hero {
  }
}

/* home_area
********************************************** */
.home_area {
  @include mq(sp, min, ps) {
    padding-bottom: rem(120);
  }
}
.home_area--read {
  margin-bottom: rem(40);
}
.home_area--list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: rem(32);
  @include mq(sp) {
    gap: rem(16);
  }
}
.home_area--list li {
  @include mq(sp) {
    width: calc((100% - sprem(48)) / 3);
  }
}

.home_area--list a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: rem(200);
  padding: rem(24) rem(20);
  font-size: rem(26);
  line-height: 1;
  color: var(--clr-wht);
  border-radius: rem(6);
  background-color: var(--clr-blu);
  @include mq(sp) {
    padding: sprem(16) sprem(12);
    font-size: sprem(18);
    width: 100%;
  }
  span {
    flex: 1;
    text-align: center;
  }
  &::after {
    content: '';
    display: block;
    @include rect(10,16);
    background-color: currentColor;
    clip-path: polygon(0 0, 2px 0, 100% 50%, 2px 100%, 0 100%, calc(100% - 2px) 50%);
  }
  &:hover {
    background-color: var(--clr-org);
  }
}

/* home_search
**************************************** */
.home_search--read {
  margin-bottom: rem(64);
}
.home_search--area .home_area--list a {
  background-color: var(--clr-blu7);
  &:hover {
    background-color: var(--clr-org);
  }
}

/* home_pickup
********************************************** */
.home_pickup {
  padding-bottom: rem(56);
  @include mq(sp) {
    padding-bottom: rem(24);
  }
}
.home_pickup--item {
  padding: rem(32);
  margin-inline: rem(10);
  border-radius: rem(6);
  border: 1px solid var(--clr-blu);
  background-color: var(--clr-wht);
  transition: var(--transit-default);
  @include mq(sp) {
    padding: sprem(24) sprem(32);
  }
  &:hover a {
    color: var(--clr-org);
  }
}
.pickup_info {
  display: flex;
  gap: rem(16);
  line-height: 1.4;
  svg {
    width: rem(24);
  }
  p {
    margin-top: 0.1em;
    flex: 1;
    @include mq(sp) {
      margin-top: 0.2em;
    }
  }
}
.pickup_name,
.pickup_name a {
  font-size: rem(22);
  font-weight: 700;
  color: var(--clr-blu);
}
.pickup--attention {
  padding-left: 1em;
  text-indent: -1em;
  margin-top: rem(16);
  font-size: rem(10);
  color: var(--clr-gry6);
}

/* home_client
********************************************** */
.home_client--scroll {
  width: 100%;
  max-width: 1024px;
  margin-inline: auto;
  overflow: hidden;
}
.home_client--box {
  display: flex;
  align-items: center;
  width: max-content;
  white-space: nowrap;
  gap: rem(16);
  @include mq(sp) {
    gap: sprem(8);
  }
}
.home_client--scroll:not(.pc-scroll) {
  @include mq(sp, min, ps) {
    display: flex;
    justify-content: center;
    .home_client--list:not(:first-child) {
      display: none !important;
    }
  }
}
.pc-scroll {
  @include mq(sp, min, ps) {
    .home_client--box {
      animation: scroll-right 40s linear infinite;
    }
    .home_client--box.speed1 {
      animation: scroll-right 60s linear infinite;
    }
    .home_client--box.speed2 {
      animation: scroll-right 80s linear infinite;
    }
  }
}
.home_client--scroll:not(.sp-scroll) {
  @include mq(sp) {
    display: flex;
    justify-content: center; 
    .home_client--list:not(:first-child) {
      display: none !important;
    }
  }
}
.sp-scroll {
  @include mq(sp) {
    .home_client--box {
      animation: scroll-right 24s linear infinite;
    }
    .home_client--box.speed1 {
      animation: scroll-right 40s linear infinite;
    }
    .home_client--box.speed2 {
      animation: scroll-right 56s linear infinite;
    }
  }
}
.list-col {
  .home_client--list {
    grid-auto-flow: inherit;
    grid-template-columns: repeat(3, auto); 
    grid-template-rows: inherit;
    @include mq(sp) {
      grid-template-columns: repeat(2, auto); 
    }
  }
  .home_client--item:nth-of-type(odd):last-of-type {
    @include mq(sp) {
      display: none;
    }
  }
}
.home_client--list {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(3, auto);
  gap: rem(16);
  @include mq(sp) {
    gap: sprem(8);
  }
}
.home_client--item {
  width: rem(240);
  aspect-ratio: 240/80;
  @include mq(sp) {
    width: sprem(180);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

/* home_advantage
********************************************** */
.home_advantage--ttl {
  font-size: rem(20);
  text-align: center;
}
.home_advantage--content {
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
}
.home_advantage--img {
  @include mq(sp) {
    max-width: 65%;
    margin-inline: auto;
  }
}
.home_advantage--txt {
  font-size: rem(16);
  span {
    margin-inline: 0.25em;
    font-weight: 700;
    background-image: linear-gradient(transparent 75%, var(--clr-yel) 0%);
  }
}

/* home_achievement
**************************************** */
.home_achievement--img {
  width: rem(140);
  aspect-ratio: 140/120;
  @include mq(sp) {
    width: sprem(100);
  }
}
.home_achievement--txt {
  flex: 1;
  text-align: left;
  @include mq(sp) {
    font-size: sprem(11);
  }
}
.home_achievement--list li {
  position: relative;
  padding-left: rem(24);
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.6em;
    @include rect(10);
    border: 1px solid currentColor;
    border-radius: 50vw;
  }
}

/* home_service
**************************************** */
.home_service {
  @include mq(sp, min, ps) {
    padding-bottom: rem(40);
  }
}
.home_service--item {
  position: relative;
  color: var(--clr-wht);
  padding: rem(32);
  aspect-ratio: 440/260;
  overflow: hidden;
  z-index: 1;
  border-radius: rem(6);
  @include mq(sp) {
    padding: sprem(16);
    aspect-ratio: 414/160;
  }
  &:hover .home_service--img {
    scale: 1.02;
    filter: brightness(.6);
    @include mq(sp) {
      filter: brightness(.5);
    }
  }
}
.home_service--img {
  position: absolute;
  inset: 0;
  margin: auto;
  z-index: -1;
  filter: brightness(.75);
  transition: var(--transit-default);
  @include mq(sp) {
    filter: brightness(.65);
  }
}
.home_service--box {
  width: 100%;
}
.home_service--box .txtarea {
  flex: 1;
}
.home_service--ttl {
  font-weight: 700;
  font-size: rem(22);
}
.home_service--ttl a,
.home_service--ttl a:hover {
  color: currentColor;
}
.home_service--txt {
  position: relative;
  font-size: rem(14);
  padding-left: rem(40);
  @include mq(sp) {
    padding-left: sprem(24);
  }
  &::before {
    content: '';
    position: absolute;
    top: 0.8em;
    left: 0;
    width: rem(24);
    border-bottom: 1px solid currentColor;
    @include mq(sp) {
      width: sprem(16);
    }
  }
}
.home_service--arrow {
  position: relative;
  border-radius: 50vw;
  @include rect(32);
  border: 2px solid currentColor;
  border-radius: 50vw;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: calc(50% + rem(1));
    translate: -50% -50%;
    @include rect(8,14);
    clip-path: polygon(0 0, 2px 0, 100% 50%, 2px 100%, 0 100%, calc(100% - 2px) 50%);
    background-color: currentColor;
  }
}
.home_service .inner:has(.cta_btn) {
  margin-top: rem(56);
}

/* home_adviser
**************************************** */
.home_adviser {
  overflow: hidden;
}
.home_adviser--content {
  margin-bottom: rem(96);
  @include mq(sp) {
    width: 80%;
    margin-inline: auto;
    margin-bottom: sprem(48);
  }
  .slick-list {
    overflow: visible !important;
  }
  .post-adviser {
    margin-inline: rem(16);
    background-color: var(--clr-wht);
    @include mq(sp) {
      margin-inline: sprem(8);
    }
  }
}

/* home_office
**************************************** */
.home_office--content {
  @include center-flex;
  flex-direction: column;
  padding: rem(72);
  color: var(--clr-wht);
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/company-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: rem(8);
  @include mq(sp) {
    padding: sprem(40) sprem(32);
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/company-bg-sp.jpg);
  }
}
.home_office--ttl,
.home_office--name {
  font-weight: 700;
}
.home_office--ttl {
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.home_office--name {
  @include mq(sp) {
    font-size: sprem(18);
  }
}
.home_office--list {
  display: grid;
}
.home_office--info {
  font-size: rem(18);
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: subgrid;
    grid-column: span 2;
  }
  &:not(:last-child) {
    @include mq(sp) {
      margin-bottom: rem(8);
    }
  }
  dt {
    @include mq(sp, min, ps) {
      padding-right: rem(40);
    }
    @include mq(sp) {
      font-weight: 700;
    }
  }
  dd {
    @include mq(sp) {
      font-size: sprem(13);
    }
  }
}
.home_office--bnr {
  width: 90vw;
  max-width: 440px;
  margin-inline: auto;
}

/* home_news
**************************************** */
.home_news--ttl {
  color: var(--clr-blu);
}