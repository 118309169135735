@use "../abstracts" as *;

/* icon
**************************************** */
.icon-mail {
  width: rem(21);
  aspect-ratio: 21/16;
  fill: var(--bg-wht);
  + * {
    margin-left: rem(8);
  }
}
