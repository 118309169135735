@use '../abstracts' as *;

/* lps_parts--cta
********************************************** */
.lps_parts--cta > .inner {
  background-color: var(--clr-wht);
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.13);
}
.lps_parts--cta .cta_contact {
  @include mq(sp) {
    padding-top: calc(1em + rem(10));
  }
}
.lps_parts--cta .bg {
  position: absolute;
  bottom: 0;
  inset-inline: 0;
  height: rem(520);
  background-position: center;
  background-size: cover;
  @include mq(sp) {
    height: sprem(350);
  }
}
.parts_cta--btn > * {
  width: rem(380);
  a {
    width: 100%;
  }
}
.lps_parts--cta .cta_tel a {
  height: 100%;
  color: var(--clr-wht);
}