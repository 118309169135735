@use "../abstracts" as *;

/* service_flow
**************************************** */
.service_flow--item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem(48);
  padding: rem(40);
  background-color: var(--clr-wht);
  box-shadow: 0 0 13px 0 rgba($clr-blk, 0.13);
  @include mq(sp) {
    gap: sprem(16);
    flex-wrap: wrap;
    padding: sprem(24);
  }
  &:not(:last-child) {
    margin-bottom: rem(32);
  }
}
.service_flow--step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(8);
  font-size: rem(20);
  font-family: var(--font-en);
  font-weight: 700;
  color: var(--clr-blu);
  line-height: 1;
  @include mq(sp) {
    order: 1;
    width: calc((100% - sprem(16)) / 2);
  }
  .num {
    font-size: rem(64);
    letter-spacing: 0.05em;
  }
}
.service_flow--content {
  flex: 1;
  @include mq(sp) {
    width: 100%;
    order: 3;
  }
}
.service_flow--ttl {
  color: var(--clr-blu);
  font-size: rem(22);
  line-height: 1.4;
  margin-bottom: rem(16);
  @include mq(sp) {
    margin-bottom: sprem(10);
  }
}
.service_flow--img {
  width: rem(150);
  @include mq(sp) {
    width: calc((100% - sprem(16)) / 2);
    order: 2;
    text-align: center;
    img {
      width: 80%;
    }
  }
}