@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
.breadcrumbs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.breadcrumbs .inner {
  padding: rem(16) 0;
  white-space: nowrap;
  @include mq(sp, min, ps) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @include mq(sp) {
    width: 68%;
    margin-left: 7%;
    padding-bottom: rem(8);
    font-size: sprem(12); 
    overflow: auto;
  }
}
.breadcrumbs .inner > span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.breadcrumbs .inner > span > span {
  display: block;
}
.breadcrumbs span > span:not(.breadcrumb_last) {
  opacity: 0.8;
}
.breadcrumbs span > span.breadcrumb_last {
  max-width: 20em;
  min-width: 5em;
  @include clip-txt;
}
.breadcrumbs .slash {
  margin-inline: rem(8);
}
.breadcrumbs a {
  &:hover {
    color: var(--clr-main);
  }
}
