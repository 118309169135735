@use '../abstracts' as *;

/* ##############################################################################

    SEARCH

############################################################################## */

#search-list .section_pdg {
  @include p-tb(rem(50));
}
#search-list .ttl-01 { margin: rem(70) 0 0; }

