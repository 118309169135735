@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  background-color: var(--clr-gry3);
  height: rem(368);
  overflow: hidden;
  @include mq(sp) {
    height: sprem(200);
    line-height: 1.4;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    aspect-ratio: 1;
  }
  &::before {
    left: 0;
    bottom: 0;
    width: rem(96);
    background-color: var(--clr-blu4);
    clip-path: polygon(0 0, 0 100%, 100% 100%);
    z-index: 1;
    @include mq(sp) {
      width: sprem(64);
    }
  }
  &::after {
    top: 0;
    right: 0;
    width: rem(336);
    background-color: var(--clr-blu5);
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    z-index: 1;
    @include mq(sp) {
      width: sprem(112);
    }
  }
  & > .inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &::after {
      content: '';
      position: absolute;
      aspect-ratio: 1;
      bottom: 0;
      right: 0;
      width: rem(216);
      background-color: var(--clr-blu6);
      clip-path: polygon(100% 0, 0 100%, 100% 100%);
      z-index: 2;
      @include mq(sp) {
        width: sprem(88);
      }  
    }
  }
}
.page_ttl--bg {
  position: absolute;
  inset: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.page_ttl-jp {
  font-size: rem(48);
  @include mq(sp) {
    position: relative;
    font-size: sprem(28);
    z-index: 10;
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  color: var(--clr-main);
  font-size: calc-fz(26);
  letter-spacing: normal;
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.page_ttl_none {
  background-color: var(--clr-gry7);
  .breadcrumbs {
    position: static;
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04 {
  position: relative;
  letter-spacing: 0.1em;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 1em;
  }
}
.ttl-01 {
  display: flex;
  flex-direction: column;
  gap: rem(8);
  font-size: rem(42);
  letter-spacing: rem(4.2);
  @include mq(sp) {
    font-size: sprem(28);
    gap: 0;
  }
  &::before {
    content: "";
    display: block;
    width: rem(48);
    height: 3px;
    margin-bottom: rem(8);
    background: linear-gradient(90deg, var(--clr-blu) 0%, var(--clr-blu) 33.33%, var(--clr-blu2) 33.34%, var(--clr-blu2) 66.66%, var(--clr-lbl) 66.67%, var(--clr-lbl) 100%);
  }
  &.txt-ctr::before {
    @include auto-margin;
  }
}
.lps_sec:not([style*="background"]):nth-child(even) .ttl-01 {
  &::before {
    background: linear-gradient(90deg, var(--clr-blu) 0%, var(--clr-blu) 33.33%, var(--clr-blu2) 33.34%, var(--clr-blu2) 66.66%, var(--clr-wht) 66.67%, var(--clr-wht) 100%);
  }
}
.ttl-01:has(.ttl-01-sub) {
  &::before {
    margin-bottom: rem(16);
    @include mq(sp) {
      margin-bottom: rem(24);
    }
  }
}
.ttl-01-sub {
  display: block;
  font-family: var(--font-en);
  color: var(--clr-main);
  font-size: rem(26);
  letter-spacing: rem(1.04);
  @include mq(sp) {
    font-size: sprem(16);
  }
}
.ttl-02 {
  font-size: rem(28);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.ttl-03 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(20);
  }
  & > span > span {
    display: inline;
    background-image: linear-gradient(transparent 66.66%, var(--clr-yel) 0%);
  }
}
.ttl-04 {
  font-size: calc-fz(22);
  @include mq(sp) {
    font-size: sprem(20);
  }
}