@use "../abstracts" as *;

/* ####################################################################################

turbo-frame

#################################################################################### */
.shadow_box {
  padding: rem(48);
  box-shadow: 0 0 13px 0 rgba($clr-blk, 0.13);
  @include mq(sp) {
    padding: sprem(32);
  }
}
.c-content-title-border-left  {
  position: relative;
  font-size: rem(26);
  padding-bottom: rem(24);
  margin-bottom: rem(40);
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, var(--clr-blu) 0%, var(--clr-blu) 25%, var(--clr-gry) 25.01%, var(--clr-gry) 100%);
  }
}

select,
option {
  color: var(--clr-blk);
}

/* --- job-search-form --- */
.page-jobs__search-content {
  display: flex;
  align-items: center;
  gap: rem(80);
  @include p-tb(rem(24));
  @include mq(sp) {
    flex-direction: column;
    align-items: flex-start;
    @include p-lr(sprem(8));
    gap: rem(16);
    border-bottom: 1px solid var(--clr-gry4);
  }
  &:first-of-type {
    padding-top: 0;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--clr-gry4);
  }
}
.page-jobs__search-content-title {
  width: rem(200);
  font-size: rem(16);
  font-weight: 500;
  .required {
    margin-left: 0.5em;
    padding: 2px 6px 4px;
    font-size: rem(12);
    line-height: 1;
    color: var(--clr-wht);
    background-color: var(--clr-red2);
    border-radius: rem(4);
  }
}
.c-search-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--clr-gry4);
  }
}
.c-search-input .c-search-input__icon {
  position: absolute;
  top: 49%;
  left: 16px;
  transform: translateY(-50%);
  width: auto;
  height: 24px;
  z-index: 1;
}
.c-job-select__category__income {
  position: relative;
  @include mq(sp) {
    width: 100%;
  }
  &::after {
    content: '';
    position: absolute;
    right: rem(16);
    top: 50%;
    translate: 0 -50%;
    @include rect(14,10);
    clip-path: polygon(0 3px, 0 0, 50% calc(100% - 3px), 100% 0, 100% 3px, 50% 100%);
    background-color: currentColor;
  }
}
.c-search-input .c-search-input__content {
  width: 25vw;
  font-size: rem(14);
  padding: 20px 18px 18px 56px;
  background-color: #fff;
  border: 1px solid var(--clr-gry4);
  border-radius: 6px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  @include mq(sp, min, ps) {
    max-width: 300px;
  }
  @include mq(sp) {
    @include p-tb(0);
    width: 100%;
    height: sprem(48);
  }
  optgroup,
  option {
    font-size: rem(18);
  }
}
.c-search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(8);
  width: 240px;
  font-size: rem(16);
  padding: rem(16);
  margin-top: rem(32);
  margin-inline: auto;
  color: var(--clr-wht);
  background-color: var(--clr-grn);
  border-radius: 6px;
  cursor: pointer;
  transition: .2s;
  border: none;
  @include mq(sp) {
    @include p-tb(0);
    height: sprem(56);
  }
  &:disabled {
    background-color: var(--clr-gry2);
  }
}
.c-search-reset-link {
  display: flex;
  justify-content: center;
  margin-top: rem(16);
  margin-inline: auto;
  width: fit-content;
  font-size: rem(14);
  border-bottom: 1px solid currentColor;
  cursor: pointer;
}
.c-job-modal--btn .c-search-set {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(8);
  width: 240px;
  padding: rem(16);
  margin-inline: auto;
  font-size:  rem(14);
  font-weight: 500;
  color: var(--clr-wht);
  background-color: var(--clr-grn);
  border-radius: 6px;
  cursor: pointer;
  transition: .2s;
  &:hover {
    color: var(--clr-wht);
    opacity: .7;
  }
}

/* --- jobs-one-click-form --- */
.jobs-one-click-form {
  .shadow_box {
    padding: p-tb(sprem(40));
  }
}
.page-index-search__area-search-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: rem(32);
  line-height: 1;
  &:not(:last-child) {
    margin-bottom: rem(48);
    @include mq(sp) {
      margin-bottom: rem(32);
    }
  }
}
.page-index-search__area-search-text {
  display: flex;
  flex-direction: column;
  gap: rem(4);
  line-height: 1.2;
  border-right: 1px solid var(--clr-gry);
  padding-right: rem(32);
  &:not(:last-child) {
  }
}
.page-index-search__area-search-content-title {
  padding-right: rem(16);
  font-weight: 700;
  @include mq(sp) {
    font-size: sprem(16);
    width: 100%;
  }
}
.page-index-search__area-serch-prefecture {
  font-weight: 500;
}
.page-index-search__area-serch-city {
  font-size: rem(13);
  color: var(--clr-gry6);
  @include mq(sp) {
    font-size: sprem(12);
  }
}

/* --- job-search --- */
.c-job-select__container-wrapper {
  padding: rem(32);
  box-shadow: 0 0 13px 0 rgba($clr-blk, 0.13);
  margin-bottom: rem(56);
}
.search_pref--item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: rem(40);
  padding: rem(24);
  @include mq(sp) {
    flex-direction: column;
    align-items: flex-start;
    gap: rem(16);
    border-bottom: 1px solid var(--clr-gry4);
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--clr-gry4);
  }
}
.search_pref--title {
  font-weight: 700;
  width: rem(200);
}
#job-search-form .c-search-input,
.search_pref--item .c-search-input {
  width: 300px;
  @include mq(sp) {
    width: 100%;
  }
}
#job-search-form .c-search-input:not(:has(.search_pref--btn)) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: rem(16);
    top: 50%;
    translate: 0 -50%;
    @include rect(14,10);
    clip-path: polygon(0 3px, 0 0, 50% calc(100% - 3px), 100% 0, 100% 3px, 50% 100%);
    background-color: currentColor;
  }
}
.search_pref--item .search_pref--btn {
  position: relative;
  width: 300px;
  padding: 20px 18px 18px 56px;
  background-color: #fff;
  font-size: rem(14);
  font-weight: 400;
  border: 1px solid var(--clr-gry4);
  border-radius: 6px;
  cursor: pointer;
  @include mq(sp, min, ps) {
    max-width: 300px;
  }
  @include mq(sp) {
    display: flex;
    align-items: center;
    width: 100%;
    @include p-tb(0px);
    height: rem(56);
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    width: rem(16);
    height: 2px;
    background-color: currentColor;
    z-index: 1;
  }
  &::before {
    right: rem(16);
  }
  &::after {
    right: rem(16);
    rotate: -90deg;
  }
}
.search_pref--selected {
  display: block;
  flex: 1;
  li {
    display: inline-block;
  }
}
.search_pref--selected li:not(:last-child) {
  &::after {
    content: '、';
    margin-inline: 0.25em;
  }
}
.c-job-modal--wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  @include center-flex;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  opacity: 0;
  pointer-events: none;
  transition: var(--transit-default);
  &.active {
    opacity: 1;
    pointer-events: inherit;
  }
}
.c-job-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  display: flex;
  flex-direction: column;
  background-color: var(--clr-wht);
  border-radius: rem(16);
  box-shadow: 0 0 13px 0 rgba($clr-blk, 0.13);
  margin: rem(32) 0;
  width: 1024px;
  max-width: 86vw;
  max-height: 86vh;
  z-index: 10;
  @include mq(1240) {
    max-height: 86svh;
    margin: 0;
  }
}
.moadl-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  @include center-flex;
  width: 100vw;
  height: 100vh;
  background-color: rgba($clr-blk, 0.13);
  z-index: 1;
}
.c-job-modal--close {
  position: relative;
  cursor: pointer;
  @include rect(32);
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 100%;
    height: 2px;
    background-color: currentColor;
  }
  &::before {
    rotate: -45deg;
  }
  &::after {
    rotate: 45deg;
  }
}
.c-job-modal--ttlbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include p-lr(rem(32));
  height: rem(80);
  border-bottom: 1px solid var(--clr-gry);
  @include mq(sp) {
    height: sprem(56);
    min-height: sprem(56);
    max-height: sprem(56);
  }
}
.c-job-modal--ttl {
  font-size: rem(24);
  font-weight: 700;
  padding-top: rem(4);
  line-height: 1;
  @include mq(sp) {
    font-size: rem(20);
  }
}
.c-job-modal--content {
  position: relative;
  overflow: auto;
  flex: 1;
}
.c-job-modal--group > label {
  @include mq(sp) {
    margin-bottom: rem(8);
  }
}
.c-job-modal--group > label,
.c-job-select__list--child > label {
  font-weight: 500;
  text-align: left;
  @include mq(sp) {
    display: flex;
    align-items: flex-start;
    gap: rem(12);
  }
  cursor: pointer;
  input {
    cursor: pointer;
    @include mq(sp) {
      margin: 0;
      margin-top: rem(6);
    }
  }
}
.c-job-select__list--child > label {
  margin-bottom: rem(8);
}
.c-job-select__list--parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: rem(24);
  height: rem(64);
  @include p-lr(rem(24));
  font-weight: 500;
  line-height: 1.4;
  transition: var(--transit-default);
  border-right: 1px solid var(--clr-gry);
  text-align: left;
  cursor: pointer;
  @include mq(sp) {
    padding: rem(10) rem(16);
    gap: rem(16);
    height: auto;
    font-size: sprem(13);
  }
  &:not(:first-child) {
    border-top: 1px solid var(--clr-gry);
  }
  &::after {
    content: '';
    @include rect(10,16);
    min-width: rem(10);
    clip-path: polygon(0 0, 2px 0, 100% 50%, 2px 100%, 0 100%, calc(100% - 2px) 50%);
    background-color: currentColor;
  }
  &.active {
    background-color: var(--clr-lbl);
  }
}
.c-job-modal--area .c-job-modal--content {
  padding: rem(48) rem(32) 0;
}
.c-job-modal--area .c-job-modal--btn {
  @include p-tb(rem(24));
}
.c-job-modal--job-type .c-job-modal--content {
  width: 25%;
  height: 100%;
  flex: 1;
  @include mq(sp) {
    width: 40%;
  }
}
.c-job-modal--job-type .c-job-modal--btn {
  border-top: 1px solid var(--clr-gry);
  @include p-tb(rem(16));
}
.c-job-select__list--child {
  position: fixed;
  top: rem(80);
  right: 0;
  width: 75%;
  padding: rem(48) rem(32);
  display: none;
  pointer-events: none;
  transition: var(--transit-default);
  overflow: auto;
  @include mq(sp) {
    top: sprem(56);
    width: 60%;
    padding: rem(24);
  }
  &.active {
    display: block;
    pointer-events: inherit;
  }
}
.c-job-select__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: rem(24);
  gap: 0 rem(8);
  @include mq(sp) {
    gap: 0 sprem(14);
  }
}
.c-job-modal--job-type .c-job-select__list {
  padding-left: rem(16);
  flex-direction: column;
}
.c-job-select__list-item * {
  cursor: pointer;
}
.c-job-select__list-item label {
  @include mq(sp) {
    display: flex;
    align-items: flex-start;
    gap: rem(10);
    text-align: left;
    line-height: 1.4;
    margin-bottom: rem(10);
  }
  input {
    @include mq(sp) {
      margin: rem(4) 0 0;
    }
  }
}
.page-jobs main {
  @include mq(sp) {
    overflow: hidden;
  }
}
.page-jobs__number-jobs {
  margin-bottom: rem(24);
  font-size: rem(18);
  font-weight: 500;
  @include mq(sp) {
    margin-top: rem(40);
    margin-bottom: rem(16);
  }
  span {
    color: var(--clr-org);
    font-size: rem(32);
    font-weight: 700;
    margin-inline: .25em;
  }
}
.page-jobs__container {
  display: flex;
  align-items: flex-start;
  gap: rem(64);
  @include mq(sp) {
    flex-direction: column;
    gap: rem(40);
  }
}
.page-jobs__container__items {
  flex: 1;
}
.page-jobs__container__link-collection {
  width: 300px;
}
.page-jobs__content-wrapper {
  padding: rem(32);
  box-shadow: 0 0 13px 0 rgba($clr-blk, 0.13);
  &:not(:last-child) {
    margin-bottom: rem(40);
  }
}
.page-jobs__content-wrapper > div {
  border: none !important;
}
.page-jobs__content-wrapper a:hover {
  color: currentColor;
}
.page-jobs__content-title {
  font-size: rem(24);
  line-height: 1.4;
  font-weight: 700;
  color: var(--clr-blu);
  @include mq(sp) {
    line-height: 1.4;
  }
}
.page-jobs__content-annual-salary,
.page-jobs__content-location {
  display: flex;
  align-items: flex-start;
  gap: rem(16);
  @include mq(sp) {
    line-height: 1.4;
  }
  img {
    width: rem(24);
    @include mq(sp, min, ps) {
      margin-top: 0.2em;
    }
  }
}

.page-jobs__content-annual-salary {
  margin-top: rem(16);
  margin-bottom: rem(8);
  font-weight: 500;
}
.page-jobs__content-location {
  font-weight: 500;
  margin-bottom: rem(24);
}
.page-jobs__content-description {
  padding-top: rem(24);
  margin-bottom: rem(16);
  font-size: rem(18);
  font-weight: 500;
  line-height: 1.4;
  border-top: 1px solid var(--clr-gry4);
}
.page-jobs__content-list {
  @include clip-txt(3);
}
.page-jobs__container__link-collection h3 {
  position: relative;
  padding-left: rem(20);
  margin-bottom: rem(8);
  font-size: rem(18);
  letter-spacing: .2em;
  line-height: 1.8;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(180deg, var(--clr-blu) 0%, var(--clr-blu) 33.33%, var(--clr-blu2) 33.34%, var(--clr-blu2) 66.66%, var(--clr-gry) 66.67%, var(--clr-gry) 100%);
  }
}
.page-jobs__container__link-collection {
  .page-index-search__area-search-content {
    gap: rem(16);
  }
}
.page-index-search__job-search-content {
  display: flex;
  flex-wrap: wrap;
  gap: rem(8);
}
.page-index-search__area-search-content a,
.page-index-search__job-search-content a {
  display: flex;
  align-items: center;
  gap: rem(8);
  @include mq(sp) {
    font-size: sprem(14);
  }
  &::before {
    content: '';
    display: block;
    padding-top: 0.1em;
    @include rect(8,12);
    clip-path: polygon(0 0, 3px 0, 100% 50%, 3px 100%, 0 100%, calc(100% - 3px) 50%);
    background-color: var(--clr-main);
  }
}
.page-index-search__area-search-content__job-type-category {
  width: 100%;
}
.page-index-search__area-search-content__area,
.page-index-search__area-search-content__job-type-category {
  font-weight: 500;
  width: 100%;
  margin-top: rem(12);
}
.page-index-search__area-search__town,
.page-index-search__area-search__job-type {
  font-size: rem(14);
}
.c-paginate {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: rem(8);
}
.c-paginate > * {
  @include center-flex;
  @include rect(40);
  font-weight: 500;
  a {
    @include center-flex;
    color: var(--clr-wht);
    background-color: var(--clr-main);
    width: 100%;
    height: 100%;
    &:hover {
      background-color: var(--clr-blu4);
    }
  }
}


/* --- job-search-show --- */
.page-jobs-detail__title {
  margin-bottom: rem(24);
  font-size: rem(36);
  font-weight: 700;
  color: var(--clr-blu);
  @include mq(sp) {
    font-size: sprem(28);
  }
}
.page-jobs-detail__annual-salary,
.page-jobs-detail__location {
  display: flex;
  align-items: flex-start;
  gap: rem(16);
  line-height: 1.6;
  img {
    width: rem(24);
    @include mq(sp, min, ps) {
      margin-top: 0.2em;
    }
  }
}
.page-jobs-detail__annual-salary {
  font-weight: 500;
  margin-bottom: rem(8);
}
.page-jobs-detail__location {
  font-weight: 500;
}
.page-jobs-detail__content {
  padding-top: rem(32);
  margin-top: rem(32);
  margin-bottom: rem(64);
  border-top: 1px solid var(--clr-gry4);
}
.page-jobs-detail__lead {
  font-size: rem(20);
  font-weight: 700;
  margin-bottom: rem(32);
}
.page-jobs-detail__point-title {
  display: flex;
  align-items: center;
  gap: rem(8);
  font-size: rem(20);
  font-weight: 700;
  margin-bottom: rem(8);
}
.page-jobs-detail__point {
  margin-bottom: rem(48);
}
.c-sub-content-title {
  position: relative;
  font-size: rem(20);
  font-weight: 700;
  padding-bottom: rem(8);
  margin-bottom: rem(24);
  padding-left: 0.25em;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, var(--clr-blu) 0%, var(--clr-blu) 25%, var(--clr-gry) 25.01%, var(--clr-gry) 100%);
  }
}
.page-jobs-detail__content-body {
  margin-bottom: rem(32);
}
.page-jobs-detail__content-body-item {
  margin-bottom: rem(16);
}
.page-jobs-detail__content-body-item-title {
  font-weight: 500;
}

.c-cv-button__lead {
  position: relative;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: auto;
  font-size: rem(14);
  font-weight: 500;
  margin-bottom: rem(8);
  @include p-lr(rem(16));
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    @include rect(12,16);
    background-color: currentColor;
  }
  &::before {
    left: 0;
    clip-path: polygon(0 0, 2px 0, 100% 100%, calc(100% - 2px) 100%);
  }
  &::after {
    right: 0;
    clip-path: polygon(calc(100% - 2px) 0, 100% 0%, 2px 100%, 0% 100%);
  }
}
.c-cv-button {
  margin-inline: auto;
  margin-bottom: rem(24);
  box-shadow: 0 3px 12px #0000001f;
}

.c-back-button {
  position: relative;
  display: block;
  width: 90vw;
  max-width: 340px;
  margin-inline: auto;
  padding: 24px 0;
  font-size: rem(15);
  font-weight: 700;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 3px 12px #0000001f;
  border: 2px solid currentColor;
  transition: .2s
}
.c-back-button:hover {
  opacity: .6;
  color: var(--clr-blk);
}
.c-back-button:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  @include rect(12,18);
  clip-path: polygon(calc(100% - 2px) 0, 100% 0, 2px 50%, 100% 100%, calc(100% - 2px) 100%, 0% 50%);
  background-color: currentColor;
}

/* --- jobs-count --- */
.page-index-search__info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: rem(8);
  font-size: rem(14);
  font-weight: 500;
  line-height: 1;
  margin-bottom: rem(24);
}
.page-index-search__sum {
  font-size: rem(24);
  font-weight: 700;
  color: var(--clr-org);
  @include mq(sp) {
    font-size: sprem(24);
  }
  span {
    font-size: rem(14);
  }
}
.page-index-search__update {
  color: var(--clr-gry5);
}

/* --- jobs-selectable-form, jobs-selectable-form_in_site --- */
#jobs-selectable-form,
#jobs-selectable-form_in_site {
  .page-index-search__input-container {
    display: flex;
    gap: rem(24);
    @include mq(sp) {
      gap: rem(16);
      flex-direction: column;
      align-items: center;
    }
  }
  .page-index-search__input-container > * {
    @include mq(sp) {
      width: 100%;
    }
  }
  .c-search-input {
    border-bottom: none !important;
    @include p-tb(0);
    flex: 1;
  }
  .c-search-input .c-search-input__content {
    width: 100%;
    max-width: inherit;
    font-size: rem(16);
    option {
      font-size: rem(18);
    }
  }
  .page-index-search__multiple-icon {
    @include mq(sp) {
      width: rem(32);
    }
  }
  .c-search-input__plus-icon {
    position: absolute;
    right: rem(16);
  }
  .c-search-button {
    margin-top: 0;
  }
}


/* jobs-one-click-form_in_site
**************************************** */
.pref_tab--list {
  display: flex;
  justify-content: space-between;
  a {
    width: 50%;
    @include p-tb(rem(8));
    color: var(--clr-wht);
    font-size: rem(18);
    font-weight: 500;
    text-align: center;
    border-radius: rem(8) rem(8) 0 0;
    background-color: var(--clr-gry2);
    cursor: pointer;
    pointer-events: inherit;
    &:hover {
      color: var(--clr-wht);
      opacity: .7;
    }
    &.active {
      background-color: var(--clr-blu);
      pointer-events: none;
    }
  }
}

.pref_tab--content {
  padding: rem(48) rem(64);
  background-color: var(--clr-wht);
  @include mq(sp) {
    padding: rem(32);
  }
  .page-index-search__area-search-content,
  .page-index-search__job-search-content {
    gap: rem(16);
  }
  .page-index-search__area-search-content__area:first-child {
    margin-top: 0;
  }
  .page-index-search__area-search-text__area {
    font-size: rem(18);
  }
  .page-index-search__area-search__town {
    font-size: rem(16);
  }
}
.pref_tab--item {
  display: none;
  &.active {
    display: block;
  }
  a {
    display: flex;
    align-items: center;
    gap: rem(8);
    &::before {
      content: '';
      display: block;
      padding-top: 0.1em;
      @include rect(8,12);
      clip-path: polygon(0 0, 3px 0, 100% 50%, 3px 100%, 0 100%, calc(100% - 3px) 50%);
      background-color: var(--clr-main);
    }
    img {
      display: none;
    }
  }
}


#jobs-one-click-form {
  .page-index-search__area-search-content a {
    &::before {
      content: none;
    }
  }
  .page-index-search__area-search-content a.page-index-search__area-search-text {
    align-items: flex-start;
  }
}
@include mq(sp) {
  #jobs-one-click-form {
    .page-index-search__area-search-content:not(:first-child) .page-index-search__area-search-content-title {
      padding-right: 0;
      padding-top: rem(32);
      border-top: 1px dashed var(--clr-gry5);
    }
    .page-index-search__area-search-content-title {
      font-size: sprem(16);
      margin-bottom: rem(8);
    }
    .page-index-search__area-search-content {
      gap: rem(16);
    }
    .page-index-search__area-search-content a.page-index-search__area-search-text {
      font-size: sprem(14);
      padding-right: rem(16);
    }
  }
}
