@use '../abstracts' as *;

/* lps_parts--column
********************************************** */
.lps_parts--column .column_item,
.lps_parts--column .text {
  display: flex;
  flex-direction: column;
}
.lps_parts--column .column_item.bg-wh .box {
  background-color: var(--clr-lbl);
}
.lps_parts--column .img {
  flex-shrink: 0;
}
.lps_parts--column .text {
  flex: 1;
}
.lps_parts--column .img + .text {
  padding-top: 8%;
  @include mq(sp) {
    padding-top: sprem(24);
  }
}
.lps_parts--column:has(.bg-wh) .column_item {
  box-shadow: 0 0 13px 0 rgba($clr-blk, 0.13);
}
.lps_parts--column .inner-sm {
  max-width: 1200px;
}
.lps_parts--column .inner-sm .column_item {
  @include mq(sp, min, ps) {
    @include p-lr(rem(120));
  }
}
.lps_parts--column .bg-wh .text {
  padding: 8%;
  @include mq(sp) {
    padding: sprem(24);
  }
}
.lps_parts--column .lps_parts--child:not(.grid-col1) table {
  width: 100%;
}
.lps_parts--column .grid-col1 .box {
  padding: rem(40);
}

// 角丸設定 ==================================

// /* 白背景 */
// .lps_parts--column {
//   .column_item.bg-wh {
//     border-radius: rem(20);
//     .img img {
//       border-radius: rem(20) rem(20) 0 0;
//     }
//   }
// }

// /* 白背景なし */
// .lps_parts--column {
//   .column_item:not(.bg-wh) .img img {
//     border-radius: rem(20);
//   }
// }

// 角丸設定 ==================================

@include mq(sp){
  .lps_parts--column + .lps_parts--column {
    margin-top: sprem(24);
  }
  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }
  .lps_parts--column .flex-sp-block .column_item:not(:last-child) {
    margin-bottom: rem(40);
  }
  .lps_parts--column .text.bg-wh,
  .lps_parts--column .grid-col1 .box {
    padding: sprem(24);
  }
}