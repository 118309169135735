@use "../abstracts" as *;

/* ########################################################################

 スタンダード用TOP

######################################################################## */
.standard {
  /* hero
********************************************** */
  .hero--cont {
    position: absolute;
    top: auto;
    left: rem(155);
    bottom: rem(126);
    @include mq(sp) {
      left: 8%;
      right: 8%;
      bottom: 10%;
    }
  }
  .hero--catch {
    font-size: rem(48);
    @include lh(48, 80);
    @include mq(sp) {
      font-size: sprem(32);
      line-height: 1.3;
    }
  }
  .hero--lead {
    @include lh(16, 32);
  }

  /* home_newsline
  ********************************************** */
  .home_newsline {
    padding-block: rem(16);
  }
  .home_newsline .inner {
    gap: rem(40);
    @include mq(sp) {
      gap: sprem(10);
    }
  }
  .home_newsline .post {
    gap: rem(40);
    margin-block: rem(-16);
    padding-block: rem(16);
    @include mq(sp, min) {
      width: vw(870);
    }
  }
  .home_newsline--more {
    display: flex;
    gap: rem(14);
    margin-block: rem(-16);
    padding-block: rem(16);
    color: var(--clr-main);
    font-size: calc-fz(15);
    @include mq(sp) {
      margin-left: auto;
    }
  }
  .home_newsline--more svg {
    width: rem(16);
    aspect-ratio: 1;
    fill: currentColor;
  }

  /* 回り込みレイアウト
  ********************************************** */
  .lps_parts--img_text .inner:not(.inner-lg) {
    width: 100%;
  }
  .lps_parts--img_text:has(.inner-lg) {
    margin-inline: calc(50% - 50vw);
    margin-bottom: rem(-80); // section_pdgの値を変更したらここも変える
    counter-reset: number;
    @include mq(sp) {
      margin-bottom: sprem(24);
    }
    .ttl-03 {
      font-size: rem(40);
      @include lh(40, 60);
      @include mq(sp) {
        font-size: sprem(28);
      }
    }
    .ttl-03::before {
      counter-increment: number;
      content: "0" counter(number);
      position: absolute;
      right: 0;
      top: -0.5em;
      z-index: -1;
      color: var(--clr-main);
      font-size: rem(200);
      font-family: var(--font-en);
      line-height: var(--line-height-none);
      letter-spacing: 0;
      opacity: 0.2;
      @include mq(sp) {
        top: -0.3em;
        font-size: sprem(100);
      }
    }
    .lps_parts + .lps_parts {
      margin-top: 0;
    }
    .img_text--txt {
      @include mq(sp, min, ps) {
        min-height: rem(720);
      }
      @include mq(sp) {
        padding-block: sprem(32);
      }
    }
  }
  .lps_parts--img_text .img {
    overflow: hidden;
  }

  /* スライド
  ********************************************** */
  .slide-query .slick-list {
    margin-inline: calc(50% - 50vw);
  }
  .slide-query .slick-slide {
    transition: var(--transit-default);
    &:not(.slick-active) {
      opacity: 0.3;
    }
  }
  .slide-query .slick-arrow {
    position: absolute;
    z-index: 1;
    display: grid;
    place-items: center;
    @include rect(64);
    color: var(--clr-main);
    border-radius: 50%;
    background-color: var(--bg-wht);
    &::before,
    &::after {
      content: "";
      position: absolute;
    }
    &::before {
      inset: 0;
      border-radius: 50%;
      box-shadow: 0 0 rem(32) var(--clr-main);
      opacity: 0.2;
    }
    &::after {
      @include rect(8);
      transform: rotate(45deg);
    }
  }
  .slide-query .slick-prev {
    &::after {
      border-bottom: 2px solid;
      border-left: 2px solid;
    }
  }
  .slide-query .slick-next {
    &::after {
      border-top: 2px solid;
      border-right: 2px solid;
    }
  }

  // posts-query01 ===============================================
  .posts-query01 .post {
    width: rem(378);
    margin-inline: rem(16);
  }
  .posts-query01 .post--txt {
    text-align: justify;
  }
  .posts-query01 .slide-query {
    .slick-arrow {
      top: rem(93);
    }
    .slick-prev {
      left: rem(-48);
      @include mq(sp) {
        left: 0;
      }
    }
    .slick-next {
      right: rem(-48);
      @include mq(sp) {
        right: 0;
      }
    }
  }

  // posts-query02 ===============================================
  .posts-query02 .post {
    width: rem(378);
    margin-inline: rem(16);
    padding: rem(38) rem(38) rem(54);
    background-color: var(--bg-wht);
    box-shadow: 0 0 rem(32) rgba(0, 0, 0, 0.1);
    border-radius: rem(8);
  }
  .posts-query02 .post--num {
    display: block;
    color: var(--clr-main);
    font-size: rem(80);
    font-family: var(--font-en);
    text-align: center;
    line-height: var(--line-height-none);
    letter-spacing: 0;
  }
  .posts-query02 .post--img {
    width: rem(184);
    margin-inline: auto;
    overflow: hidden;
    border-radius: 50%;
    aspect-ratio: 1;
  }
  .posts-query02 .post--ttl {
    text-align: center;
  }
  .posts-query02 .post--txt {
    text-align: justify;
  }
  .posts-query02 .slide-query {
    .slick-track {
      display: flex;
    }
    .slick-list {
      padding-top: rem(103) !important;
      padding-bottom: rem(32) !important;
      @include mq(sp, min) {
        padding-left: calc(rem(378) - rem(16));
      }
    }
    .slick-slide {
      height: auto;
    }
    .slick-arrow {
      top: 0;
    }
    .slick-prev {
      right: rem(87);
    }
    .slick-next {
      right: 0;
    }
  }

  /* 背景写真カラム
  ********************************************** */
  .bg_section {
    position: relative;
    display: flex;
    align-items: center;
    padding-block: rem(80);
    @include mq(sp, min, ps) {
      min-height: rem(720);
    }
    &:nth-of-type(odd) {
      .bg_section--txt {
        margin-left: auto;
      }
    }
  }
  .bg_section--img {
    position: absolute;
    inset: 0;
    z-index: -1;
  }
  @include mq(sp, min) {
    .bg_section--txt {
      width: rem(483);
    }
  }

  /* 新着情報、ブログ
  ********************************************** */

  // home_information01 ===============================================
  .home_information01 {
    display: flex;
    gap: rem(16);
    @include mq(sp) {
      flex-direction: column;
    }
    + .home_information01 {
      margin-top: rem(100);
    }
  }
  .home_information01--ttl {
    flex: 1;
  }
  @include mq(sp, min) {
    .home_information01--cont {
      width: rem(800);
    }
  }
  @include mq(sp) {
    .home_information01--ttl {
      text-align: center;
    }
    .home_information01--ttl .ttl-01-sub::after {
      margin-inline: auto;
    }
    .home_information01 .btn-arrow {
      text-align: center;
    }
  }

  .home_information01 {
    .posts-news .post--txt,
    .posts-blog .post--txt {
      -webkit-line-clamp: 1;
      opacity: 0.5;
      pointer-events: none;
    }

    // posts-news
    .posts-news .post--date {
      font-size: calc-fz(18);
    }
    .posts-news .post--txtarea {
      flex: 1;
    }

    // posts-blog
    .posts-blog .post {
      display: flex;
      padding-block: rem(28);
    }
    .posts-blog .post--date {
      font-size: calc-fz(13);
    }
    .posts-blog .post--img {
      width: rem(150);
      margin-right: rem(24);
      margin-bottom: 0;
    }
  }

  // home_information02 ===============================================
  .home_information02--wrap {
    display: grid;
    gap: rem(20) rem(75);
    @include mq(sp, min, ps) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .home_information02 {
    @include mq(sp, min) {
      display: grid;
      grid-template-rows: subgrid;
      grid-row: span 2;
      gap: rem(10);
    }

    .posts-news .post--date,
    .posts-blog .post--date {
      font-size: calc-fz(13);
    }

    // posts-news
    .posts-news {
      padding: rem(40) rem(48);
      @include mq(sp) {
        padding: sprem(8) sprem(16);
      }
    }
    .posts-news .post {
      display: block;
      &:first-child {
        border-top: none;
      }
      &:last-child {
        border-bottom: none;
      }
    }
    .posts-news .post--txt {
      -webkit-line-clamp: 2;
      opacity: 0.5;
      font-size: calc-fz(14);
      @include lh(14, 22);
      pointer-events: none;
    }
    .posts-news .post--info {
      margin-bottom: rem(8);
    }
    .posts-news .post--ttl {
      margin-bottom: rem(8);
    }

    // posts-blog
    .posts-blog .post {
      display: flex;
      padding: 0;
      border: none;
      &:not(:last-child) {
        margin-bottom: rem(32);
      }
    }
    .posts-blog .post--img {
      width: rem(216);
      margin-right: rem(32);
      margin-bottom: 0;
    }
    .posts-blog .post--info {
      align-items: flex-start;
      flex-direction: column-reverse;
    }
    .posts-blog .cat_list {
      margin-left: 0 !important;
      margin-bottom: rem(8);
    }
  }

  /* お問い合わせ
  ********************************************** */
  .home_cta {
    position: relative;
    padding-top: rem(80);
    padding-bottom: rem(192);
    @include mq(sp) {
      padding-bottom: sprem(100);
    }
  }
  .home_cta .inner {
    padding: rem(120);
    border-radius: rem(8);
    @include mq(sp) {
      padding: sprem(32);
    }
  }
  .home_cta--bg {
    position: absolute;
    bottom: 0;
    z-index: -1;
    inset-inline: 0;
    aspect-ratio: 1920/520;
    @include mq(sp) {
      aspect-ratio: 375/200;
    }
  }

  /* フッター上リンク（名称求む…）
  ********************************************** */
  .home_footer_toplink .related_page--list {
    border-radius: 0;
  }
}
