@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;
$wid-var: 1700; // フォントサイズは画面幅がこの値～$wid-pcで可変する
$header-hgt: 96;
$header-hgt-sp: 64;
$header-hgt-fx: 80;

// 色
$clr-main: #1d7fb7;
$clr-blk: #000;
$clr-blk2: #333;
$clr-wht: #fff;
$clr-gry: #ddd;
$clr-gry2: #bfbfbf;
$clr-gry3: #fafafa;
$clr-gry4: #7c7c7c;
$clr-gry5: #5b5b5b;
$clr-gry6: #8d8d8d;
$clr-gry7: #f9f9f9;
$clr-org: #f70;
$clr-org2: #ffe2c8;
$clr-lbl: #e3f1ff;
$clr-lbl2: #ffead5;
$clr-blu: #2666a4;
$clr-blu2: #1eb8d4;
$clr-blu3: #3185d5;
$clr-blu4: #8fc0ef;
$clr-blu5: #e9f0f8;
$clr-blu6: #b6d5f1;
$clr-blu7: #4e8ac5;
$clr-blu8: #80b6eb;
$clr-yel: #fffa62;
$clr-red: #f77f7f;
$clr-red2: #ff0000;
$clr-grn: #60ab0f;

// CSS変数
:root {

  // colors
  --clr-main: #{$clr-main};
  --clr-body: #{$clr-blk};
  --clr-link: #{$clr-blk};
  --clr-blk: #{$clr-blk};
  --clr-blk2: #{$clr-blk2};
  --clr-wht: #{$clr-wht};
  --clr-gry: #{$clr-gry};
  --clr-gry2: #{$clr-gry2};
  --clr-gry3: #{$clr-gry3};
  --clr-gry4: #{$clr-gry4};
  --clr-gry5: #{$clr-gry5};
  --clr-gry6: #{$clr-gry6};
  --clr-org: #{$clr-org};
  --clr-org2: #{$clr-org2};
  --clr-lbl: #{$clr-lbl};
  --clr-lbl2: #{$clr-lbl2};
  --clr-blu: #{$clr-blu};
  --clr-blu2: #{$clr-blu2};
  --clr-blu3: #{$clr-blu3};
  --clr-blu4: #{$clr-blu4};
  --clr-blu5: #{$clr-blu5};
  --clr-blu6: #{$clr-blu6};
  --clr-blu7: #{$clr-blu7};
  --clr-blu8: #{$clr-blu8};
  --clr-yel: #{$clr-yel};
  --clr-red: #{$clr-red};
  --clr-red2: #{$clr-red2};
  --clr-grn: #{$clr-grn};

  // typography
  --ltr-space-default: 0.1em;
  --line-height-default: 1.833;
  --line-height-hdr: 1.5;
  --line-height-none: 1;

  // font-families
  --font-primary: 'Noto Sans JP', sans-serif;
  --font-jp: 'Noto Sans JP', sans-serif;
  --font-en: 'Akshar', sans-serif;

  // background colors
  --bg-main: #1d7fb7;
  --clr-blu: #{$clr-blu};
  --bg-sub: #f8f8f8;
  --bg-wht: #fff;
  --bg-off_wht: #f5f2ed;
  --bg-blk: #000;
  --bg-cta: #{$clr-org};
  --bg-cta-hvr: #dd6b2a;

  // border colors
  --border-main: #404040;

  // animation
  --transit-default: all 0.4s ease-out;

}


// SASS Maps

$z-index: (
  "header": 9999,
  "top": 9990,
  "aside": 75,
  "middle": 50,
  "main": 1,
  "base": 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160,
  // 2560px
  lg: 120,
  // 1920px
  pc: 85.375,
  // 1366px
  med: 67.5,
  // 1080px
  sp: 47.9375,
  // 767px
  sm: 40,
  // 640px
  xs: 34.375,
  // 500px
  se: 21.25,
  // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  "blue": (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  "red": (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  "green": (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  ),
);

$aspect-ratios: (
  widescreen: "3/2",
  square: "1/1",
  portrait: "2/3",
);