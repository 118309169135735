@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem($header-hgt);
  background-color: var(--bg-wht);
  z-index: z(header);
  transition: var(--transit-default);
  box-shadow: 0 3px 12px rgba($clr-blk, 0.12);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
  @include mq(med) {
    height: sprem($header-hgt-sp);
  }
}
.page-contact-simple .header {
  position: absolute;
}
.header-sm {
  @include mq(med, min, ps) {
    height: rem($header-hgt-fx);
  }
}
.home .header,
.page-prefectures .header {
  background-color: transparent;
  box-shadow: inherit;
}
.home .header-sm,
.page-prefectures .header-sm {
  background-color: var(--bg-wht);
  box-shadow: 0 3px 12px rgba($clr-blk, 0.12);
}

.home .gnav_btn--lines span,
.page-prefectures .gnav_btn--lines span {
  background-color: var(--clr-wht);
}
.home .header-sm .gnav_btn--lines span,
.page-prefectures .header-sm .gnav_btn--lines span {
  background-color: var(--clr-blk);
}


/* --- logo -- */
.header--logo {
  width: 258px;
  height: 100%;
  @include p-lr(rem(24));
  background-color: var(--clr-wht);
  @include mq(med) {
    width: spvw(180);
  }
}
.nav--logo {
  display: block;
  width: 210px;
}
.header--logo a {
  @include center-flex;
  height: 100%;
  &:hover {
    opacity: 0.7;
  }
}
.footer--logo a {
  display: inline-block;
  &:hover {
    opacity: 0.7;
  }
}
.header--logo img {
  width: 160px;
  @include mq(sp) {
    width: auto;
    max-height: rem(40);
  }
}

/* gnav
********************************************** */
.gnav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 300;
  width: 100%;
  height: 100vh;
  margin: 0;
  @include p-tb(rem(200),rem(40));
  opacity: 0;
  pointer-events: none;
  overflow: auto;
  background-color: var(--clr-wht);
  transform: translateX(100%);
  -webkit-overflow-scrolling: touch;
  transition: var(--transit-default);
  &.active {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(0);
  }
  @include mq(med, min, ps) {
    height: 100%;
  }
  @include mq(med) {
    top: 0;
    @include p-tb(rem(64),rem(40));
    height: 100%;
  }
}
.gnav--inner {
  @include mq(sp) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

/* --- menu --- */
.gnav--menu,
.gnav--cta {
  @include mq(sp) {
    display: block;
  }
}
.gnav-list {
  @include mq(sp) {
    width: 100%;
  }
}
.gnav-list--item {
  padding: rem(16) rem(40);
  @include mq(1580, min, ps) {
    min-width: 260px;
  }
  @include mq(1580) {
    max-width: rem(260);
    @include p-lr(rem(32));
  }
  @include mq(med, min, ps) {
    border-left: 1px solid var(--clr-gry);
  }
  @include mq(med) {
    max-width: inherit;
    width: 100%;
    padding: 0;
  }
  &:not(:last-child) {
    @include mq(med) {
      border-bottom: 1px dotted var(--clr-main);
    }
  }
}
.gnav-list--item li {
  @include mq(med) {
    position: relative;
    display: block;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px dotted var(--clr-main);
    }
  }
}
.menu-item 
.gnav--link {
  @include mq(med) {
    padding: rem(18) rem(16);
  }
}
.menu-item:not(:last-child) {
  @include mq(sp, min, ps) {
    margin-bottom: rem(16);
  }
}
.gnav--menu li:not(.menu-item-has-children) a::before {
  @include mq(med) {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: sprem(10);
    margin: auto;
    @include sprect(7);
    border-top: 1px solid var(--clr-main);
    border-right: 1px solid var(--clr-main);
    transform: rotate(45deg);
  }
}
.gnav--link:has([href]):hover {
  @include mq(med, min, ps) {
    color: var(--clr-main);
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}
.head_sub_nav--wrap {
  display: block;
  width: 100%;
  transition: var(--transit-default);
  @include mq(med, min, ps) {
    padding-top: rem(16);
  }
  @include mq(med) {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-main);
    transition: none;
    display: none;
  }
}
.head_sub_nav {
  @include mq(med) {
    position: relative;
  }
}
.head_sub_nav .menu-item:not(:last-child) {
  @include mq(sp, min, ps) {
    margin-bottom: rem(12);
  }
}
.head_sub_nav a {
  position: relative;
  display: block;
  font-weight: 300;
  @include mq(med, min, ps) {
    padding-left: rem(24);
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.55em;
      @include rect(10,14);
      clip-path: polygon(0 0, 3px 0, 100% 50%, 3px 100%, 0 100%, calc(100% - 3px) 50%);
      background-color: var(--clr-main);
    }
  }
  @include mq(med) {
    font-weight: 500;
    padding: rem(12) rem(16) rem(12) rem(32) !important;
  }
  &:hover {
    @include mq(med, min, ps) {
      color: var(--clr-main);
    }
  }
}
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: absolute;
    top: sprem(3);
    right: 0;
    @include sprect(56);
    transition: var(--transit-default);
    color: var(--clr-main);
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: rem(11);
      height: 1px;
      border-bottom: 1px solid;
    }
    &::after {
      transform: rotate(90deg);
      transition: var(--transit-default);
    }
    &.active::after {
      transform: rotate(180deg);
    }
  }
}

/* ---btn --- */
.gnav--menu {
  height: 100%;
}
.gnav--link {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 600;
  font-size: rem(18);
  line-height: 1.6;
}
.gnav--link[target*="_blank"] {
  &::after {
    content: '';
    display: block;
    @include rect(18);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/icon-new-tab.svg);
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.gnav_btn {
  position: relative;
  height: 100%;
  aspect-ratio: 1;
  cursor: pointer;
  z-index: 9999;
}
.gnav_btn {
  right: 0;
  transition: var(--transit-default);
}
.gnav_btn--lines {
  position: relative;
  @include sprect(24, 16);
}
.gnav_btn--lines span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--clr-blk);
  transition: var(--transit-default);
  &:nth-of-type(1) {
    top: 0;
  }
  &:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  &:nth-of-type(3) {
    bottom: 0;
  }
}
.active .gnav_btn--lines span {
  background-color: var(--clr-blk);
  &:nth-of-type(1) {
    transform: translateY(sprem(7)) rotate(-45deg);
  }
  &:nth-of-type(2) {
    opacity: 0;
  }
  &:nth-of-type(3) {
    transform: translateY(sprem(-7)) rotate(45deg);
  }
}
.gnav--cta {
  text-align: center;
}
.gnav--cta li:not(:last-child) {
  @include mq(med) {
    margin-bottom: sprem(16);
  }
}
.gnav--entry {
  @include center-flex;
  @include p-lr(rem(24));
  color: var(--clr-wht);
  font-weight: 600;
  font-size: rem(14);
  background-color: var(--clr-org);
  @include mq(sp) {
    display: none;
  }
  &:hover {
    color: var(--clr-wht);
  }
}
.gnav--tel,
.gnav--contact {
  @include mq(med, min, ps) {
    margin-left: rem(8);
  }
}
.gnav--tel a {
  display: inline-flex !important;
  flex-direction: column;
}
.gnav--tel .tel {
  font-size: calc-fz(20);
}
.gnav--tel .num {
  font-size: calc-fz(24);
}
.gnav--tel .hours {
  font-size: calc-fz(14);
}
.gnav--contact a {
  padding: rem(24);
  @include mq(med, min, ps) {
    border-radius: 0;
  }
  @include mq(med) {
    padding: sprem(10) sprem(16);
    margin-left: auto;
    margin-right: auto;
  }
}

/* --- nav--info --- */
.nav--info p {
  letter-spacing: normal;
}
.nav--info .copy {
  color: var(--clr-gry4);
  font-size: rem(14);
}

/* fix_cta-sp
**************************************** */
@include mq(sp) {
  .fix_cta-sp {
    position: fixed;
    bottom: 0;
    width: 100%;
    @include p-tb(rem(12));
    background-color: rgba($clr-blk, 0.4);
    translate: 0 100%;
    transition: var(--transit-default);
    z-index: 999;
    &.active {
      translate: 0 0;
    }
  }
  .fix_cta-sp .btn-org a {
    font-size: sprem(16);
    height: sprem(64);
    span {
      flex: 1;
      text-align: center;
    }
    &::after {
      content: '';
      display: block;
      @include rect(8);
      margin-right: 0;
      margin-left: auto;
      border-top: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      transition: var(--transit-default);
      order: 1;
    }
  }
}