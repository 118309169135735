@use "../abstracts" as *;

/* ####################################################################################

  section_cta

#################################################################################### */
.section_cta {
  @include p-tb(rem(100));
  color: var(--clr-wht);
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-cta.jpg);
  background-size: cover;
  background-position: center bottom;
  background-color: var(--clr-blu3);
  &.section_cta-recruiter {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-cta-recruiter.jpg);
  }
  @include mq(sp) {
    @include p-tb(sprem(56));
  }
  & > .inner {
    align-items: center;
  }
}
.section_cta--ttl {
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.tel_link {
  display: flex;
  align-items: center;
  gap: rem(16);
  @include p-tb(rem(24),rem(8));
  font-size: rem(52);
  font-family: var(--font-en);
  font-weight: 600;
  color: var(--clr-wht);
  @include mq(sp) {
    font-size: sprem(40);
    justify-content: center;
    line-height: 1;
  }
  svg {
    width: sprem(24);
  }
  &:hover {
    color: var(--clr-wht);
  }
}
.section_cta .hours {
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
.section_cta .btn-org a {
  @include center-flex;
  width: 300px;
  min-height: 80px;
  border-radius: rem(4);
  @include mq(sp) {
    min-height: 64px;
  }
}

/* --- section_cta-job --- */
.section_cta-job {
  position: relative;
  padding: rem(64) rem(100);
  color: var(--clr-wht);
  background-color: var(--clr-blu8);
  border-radius: 6px;
  @include mq(sp) {
    padding: rem(56) rem(40);
  }
  &::after {
    content: '';
    position: absolute;
    inset: 8px;
    margin: auto;
    border: 2px solid currentColor;
    border-radius: 6px;
    pointer-events: none;
  }
  .section_cta--txt {
    flex: 1;
  }
  .section_cta--img {
    @include mq(sp) {
      width: 50% !important;
      margin-inline: auto;
    }
  }
}

/* ##############################################################################

    FOOTER

############################################################################## */

.footer {
  @include p-tb(rem(64),rem(56));
  @include mq(sp) {
    padding-bottom: rem(128);
  }
}

/* footer_top
********************************************** */
/* ---fnav --- */
.fnav--menu a {
  display: block;
  padding: 0.5em 1em;
  &:hover {
    opacity: 0.7;
  }
  @include mq(sp) {
    position: relative;
    padding: 0.5em;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0.5em;
      margin: auto;
      @include sprect(6);
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
    }
  }
}
.fnav--menu .sub-menu {
  font-size: calc-fz(15);
  line-height: 1;
  opacity: 0.75;
}

/* ---sns_area --- */
.sns_area a {
  display: grid;
  place-items: center;
  @include rect(40);
  &:hover {
    opacity: 0.7;
  }
}
.sns_area svg {
  @include rect(24);
}

/* footer_btm
*************************************************** */
.footer_btm {
  padding: rem(8) 0;
}
.footer_btm:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.privacy {
  @include mq(sp) {
    text-align: center;
    margin-bottom: 0.5em;
  }
}
.privacy a:hover {
  opacity: 0.7;
}
.copyright {
  gap: 1em;
  justify-content: center;
  @include mq(sp) {
    gap: 0.5em;
  }
}
.pbl a,
.pbl img {
  display: block;
}
.pbl a {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

/* ##############################################################################

    スタンダード用

############################################################################## */

/* footer-simple
*************************************************** */
.footer-simple {
  padding: rem(8) 0;
}
.footer-simple:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}