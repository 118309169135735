@use "../abstracts" as *;

/* bg
********************************************** */
.bg-main {
  background-color: var(--bg-main);
}
.bg-sub {
  background-color: var(--bg-sub);
}
.bg-wh {
  background-color: var(--bg-wht);
}
.bg-lbl {
  background-color: var(--clr-lbl);
}
.bg-gry {
  background-color: var(--clr-gry3);
}
