@use "../abstracts" as *;

/* --- inner --- */
.inner {
  width: 90%;
  max-width: rem(1200);
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: 86%;
  }
}
.inner-sm {
  max-width: rem(720);
}
.inner-lg {
  max-width: rem(1456);
}
.inner-xl {
  max-width: rem(1760);
}
.inner-900 {
  max-width: rem(900);
}
.inner-1024 {
  max-width: rem(1024);
}
.inner-1140 {
  max-width: rem(1140);
}
.inner-1320 {
  max-width: rem(1320);
}
.inner-1500 {
  max-width: rem(1500);
}
.inner-1640 {
  max-width: rem(1640);
}
.inner-1680 {
  max-width: rem(1680);
}