@use "../abstracts" as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

.main_side {
  display: grid;
  grid-template-columns: auto max(300px, rem(360));
  gap: rem(56);
  @include mq(med) {
    grid-template-columns: repeat(1,minmax(0,1fr));
    gap: sprem(40);
  }
}

/* main_column
**************************************** */

/* --- post --- */
.post {
  position: relative;
}
.post--img {
  background-color: var(--bg-wht);
}
.post--img .img-contain {
  max-width: 80%;
}
.post--link {
  &:not([href="javascript:void(0);"]):hover {
    // color: var(--clr-main);
  }
  &:hover svg {
    fill: currentColor;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;
  }
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: rem(8);
}
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  color: var(--clr-blu);
  font-size: calc-fz(13);
  padding-inline: 1em;
}
.cat_list a:hover {
  color: var(--clr-org);
}

/* side_column
**************************************** */
.side_box {
  padding: rem(24) rem(12);
  background-color: var(--clr-lbl);
  @include mq(sp) {
    @include p-lr(rem(16));
  }
  &:not(:last-child) {
    margin-bottom: rem(32);
  }
}
.side_section:not(:last-child) {
  margin-bottom: rem(30);
}
.side--ttl {
  position: relative;
  padding-left: rem(20);
  margin-bottom: rem(20);
  color: var(--clr-blu);
  font-size: rem(18);
  letter-spacing: .2em;
  line-height: rem(24);
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: rem(24);
    background: linear-gradient(0deg, var(--clr-blu) 0%, var(--clr-blu) 33.33%, var(--clr-blu2) 33.34%, var(--clr-blu2) 66.66%, var(--clr-wht) 66.67%, var(--clr-wht) 100%);
    @include mq(sp) {
      left: 2px;
    }
  }
}
.side--ttl small {
  opacity: .5;
  letter-spacing: .15em;
  display: block;
  line-height: 1;
  margin-top: rem(8);
}
.side_content {
  padding: rem(20) rem(16);
  background-color: var(--clr-wht);
}


/* --- list --- */
.side--list a {
  display: block;
  color: var(--clr-blu);
  font-weight: 600;
  &:hover {
    color: var(--clr-org);
  }
}

.side--list.cate li:not(:last-child) {
  margin-bottom: rem(10);
  padding-bottom: rem(10);
  border-bottom: 1px dotted var(--clr-gry2);
}
.side--list.cate a {
  position: relative;
  @include p-lr(rem(8));
  &::before,
  &::after {
    content: '';
    position: absolute;
  }
  &::before {
    top: 0;
    right: rem(8);
    @include rect(24);
    border-radius: 50vw;
    background-color: currentColor;
  }
  &::after {
    top: 0.35em;
    right: calc(rem(8) + 0.4em);
    @include rect(8,12);
    clip-path: polygon(0 0, 3px 0, 100% 50%, 3px 100%, 0 100%, calc(100% - 3px) 50%);
    background-color: var(--clr-wht);
    @include mq(sp) {
      right: calc(rem(8) + 0.5em);
      clip-path: polygon(0 0, 2px 0, 100% 50%, 2px 100%, 0 100%, calc(100% - 2px) 50%);
    }
  }
}
.side--list.tag {
  @include p-lr(rem(16));
  @include mq(sp) {
    @include p-lr(rem(8));
  }
}
.side--list.tag a {
  @include p-lr(2px);
  line-height: 1.2;
  background-image: linear-gradient(transparent calc(100% - 1px), currentColor 0%);
  &::before {
    content: '#';
  }
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: rem(10);
  padding-bottom: rem(10);
  border-bottom: 1px dotted var(--clr-gry2);
  @include mq(sp) {
    margin-bottom: rem(12);
    padding-bottom: rem(12);
  }
}
.posts-side .post--img {
  margin-right: rem(16);
  width: rem(64);
  aspect-ratio: 3/2;
  @include mq(sp) {
    width: rem(96);
  }
}
.posts-side .txtarea {
  flex: 1;
}
.posts-side .post--date {
  margin-bottom: 0;
}
.posts-side .post--ttl {
  font-weight: 300;
  line-height: 1.4;
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  &::after {
    content: "";
    display: block;
    @include rect(8);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
    transition: var(--transit-default);
  }
  &.active::after {
    transform: rotate(315deg);
  }
}
.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}
.archive-pulldown .archive_list {
  position: relative;
  &:not(:last-child) {
    margin-right: rem(24);
  }
}
.archive-pulldown .archive_list a {
  display: block;
  padding: .25em 1em;
  text-align: left;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  &:hover {
    background-color: #eee;
  }
}
.archive-pulldown .archive_list--label {
  margin-right: rem(8);
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: .25em 4em .25em 2em;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  color: inherit;
  font-family: var(--font-primary);
  font-size: calc-fz(16);
  line-height: var(--line-height-default);
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: .4em;
    right: .8em;
    margin: auto;
    width: .6em;
    height: .6em;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
    transition: var(--transit-default);
  }
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: var(--transit-default);
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}


/* column
**************************************** */
.posts-column .post {
  padding-bottom: rem(48);
  padding-top: rem(48);
  border-bottom: 1px solid var(--clr-gry2);
  @include mq(sp) {
    display: block;
    padding-top: sprem(24);
    padding-bottom: sprem(24);
  }
  &:first-child {
    border-top: 1px solid var(--clr-gry2);
  }
}
.posts-column .post--info .post--date {
  margin-right: 0;
  margin-left: auto;
}
.posts-column .post--txtarea {
  flex: 1;
  word-break: break-word;
}
.posts-column .post--ttl {
  font-size: calc-fz(18);
}
.posts-column .post--img {
  width: rem(280);
  margin-right: rem(32);
  @include mq(sp) {
    padding-left: 0;
    @include auto-margin;
    margin-bottom: sprem(16);
    text-align: center;
  }
}
.posts-column .post--ttl a {
  font-size: rem(22);
  color: var(--clr-blu);
  &:hover {
    color: var(--clr-org);
  }
}
.cat_list.column a {
  position: relative;
  color: var(--clr-gry5);
  font-size: rem(15);
  font-weight: 600;
  padding: rem(16) rem(20);
  line-height: 1;
  border: 1px solid currentColor;
  z-index: 1;
  &:hover {
    color: var(--clr-blu);
  }
}
.tag_list {
  display: flex;
  flex-wrap: wrap;
  gap: rem(24)
}
.tag_list a {
  position: relative;
  @include p-lr(2px);
  color: var(--clr-gry5);
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.2;
  background-image: linear-gradient(transparent calc(100% - 1px), currentColor 0%);
  z-index: 1;
  &::before {
    content: '#';
  }
  &:hover {
    color: var(--clr-blu);
  }
}

@include mq(sp) {
  .posts-blog .post--img img {
    width: auto;
    max-width: 100%;
  }
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: rem(20);
  padding-bottom: rem(20);
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: var(--clr-wht);
}

/* --- posts-news --- */
.posts-news .post {
  padding-bottom: rem(24);
  padding-top: rem(24);
  padding-right: rem(64);
  margin-bottom: 0;
  border-bottom: 1px solid var(--clr-gry2);
  @include mq(sp) {
    padding-right: sprem(56);
  }
  &:first-child {
    border-top: 1px solid var(--clr-gry2);
  }
  &:not(:has([href="javascript:void(0);"]))::after {
    content: '';
    position: absolute;
    right: rem(8);
    bottom: rem(16);
    width: rem(80);
    aspect-ratio: 80/32;
    background-color: var(--clr-gry2);
    clip-path: polygon(50% 0%, calc(50% + 2px) 0, 100% calc(100% - 2px), 100% 100%, 0 100%, 0 calc(100% - 2px), calc(100% - 2px) calc(100% - 2px));
    @include mq(sp) {
      width: sprem(48);
      bottom: rem(24);
    }
  }
  @include mq(sp) {
    display: block;
  }
}
.posts-news .post--info {
  @include mq(sp, min, ps) {
    margin-right: rem(32);
  }
  @include mq(sp) {
    margin-bottom: sprem(8);
  }
}
.posts-news .post--date {
  font-weight: 500;
  @include mq(sp) {
    font-size: sprem(13);
  }
}
.posts-news .post--ttl {
  font-size: calc-fz(18);
  font-weight: 500;
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.posts-news .post--info.flex .post--date + .cat_list {
  margin-left: rem(16);
}


/* case
**************************************** */
.post-case {
  padding: rem(32);
  box-shadow: 0 0 13px 0 rgba($clr-blk, 0.13);
  .post--link:hover {
    color: var(--clr-org);
  }
  .post--ttl {
    margin-bottom: rem(24);
  }
}
.post--person {
  font-weight: 600;
}
.gender.man {
  color: var(--clr-blu3);
}
.gender.women {
  color: var(--clr-red);
}
.case_data--box {
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    @include rect(16,32);
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    background-color: var(--clr-org);
    @include mq(sp) {
      top: calc(50% - rem(4));
      @include rect(28,14);
      clip-path: polygon(0 0, 50% 100%, 100% 0);
    }
  }
}
.case_data {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  & > * {
    width: 100%;
  }
  dt {
    color: var(--clr-wht);
    @include p-tb(rem(12));
    line-height: 1;
    text-align: center;
    @include mq(sp) {
      @include p-tb(rem(10));
    }
  }
  dd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: rem(16) rem(24);
    text-align: center;
    @include mq(sp) {
      @include p-tb(rem(12))
      
    }
  }
  .num {
    font-size: rem(18);
    margin-inline: .25em;
  }
}
.case_data.before {
  background-color: rgba($clr-blu4, 0.1);
  dt {
    background-color: var(--clr-blu4);
  }
}
.case_data.after {
  background-color: rgba($clr-org, 0.1);
  dt {
    background-color: var(--clr-org);
  }
  .num {
    color: var(--clr-org);
    font-size: rem(24);
  }
}

/* adviser
**************************************** */
.adviser_main {
  position: relative;
  display: flex;
  justify-content: flex-end;
  @include mq(sp, min, ps) {
    padding-top: rem(80);
  }
  @include mq(sp) {
    flex-direction: column;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 80%;
    height: 90%;
    z-index: -1;
    background-image: radial-gradient(var(--clr-lbl) 30%, transparent 30%);
    background-size: 16px 16px;
    @include mq(sp) {
      width: 94%;
      height: 90%;
      background-size: 10px 10px;
    }
  }
}
.adviser_main--txt {
  position: relative;
  padding: rem(40);
  width: 56%;
  border: 4px solid var(--clr-main);
  background-color: var(--clr-wht);
  z-index: 10;
  @include mq(sp) {
    width: 90%;
    padding: sprem(24);
    margin-top: rem(-40);
    margin-right: 0;
    margin-left: auto;
    border-width: 2px;
  }
}
.adviser_main--ttl {
  display: flex;
  flex-direction: column;
  gap: rem(4);
  font-size: rem(24);
  .sub {
    color: var(--clr-org);
    font-size: rem(18);
  }
}
.adviser_main--img {
  @include mq(sp, min, ps) {
    position: absolute;
    top: 0;
    left: 0;
    width: 56%;
    z-index: 9;  
  }
  @include mq(sp) {
    width: 90%;
  }
}
.post-adviser {
  padding: rem(32);
  box-shadow: 0 0 13px 0 rgba($clr-blk, 0.13);
  @include mq(sp) {
    padding: sprem(12) sprem(12) sprem(16);
  }
  .post--img {
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
    @include mq(sp) {
      margin-bottom: sprem(8);
    }
  }
  .post--img img {
    transition: var(--transit-default);
  }
  &:hover .post--img img {
    scale: 1.05;
  }
  ul.adviser_point--list li {
    font-size: rem(14);
    @include mq(sp) {
      font-size: sprem(12);
      line-height: 1.4;
      margin-left: perc(32,16,em);
      &::before {
        top: perc(10,16,em);
        left: perc(-28,16,em);
        width: perc(20,16,em);
      }
      &::after {
        top: perc(16,16,em);
        left: perc(-22,16,em);
        width: perc(9,16,em);
        height: perc(6,16,em);
      }
    }
  }
}
