@use "../abstracts" as *;
/* ##############################################################################

    KEYFRAMES

############################################################################## */

@keyframes loading {
  0% { rotate: 0deg; }
  100% { rotate: 360deg; }
}

@keyframes scroll-right {
  0% { transform: translate(-33.33%, 0%) }
  100% { transform: translate(-66.66%, 0%) }
}
